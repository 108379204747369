'use strict';

module.exports = {

  setPhoneNumberCallable: function() {
    var $phoneLink = $('#phone');

    var phoneNumber = $phoneLink
                        .text()
                        .replace(/\s/g, '');

    $phoneLink
      .attr('href', 'tel:'+phoneNumber)
      .removeClass('link-disabled')
  },

  initHeaderSlider: function(params) {

    // Initialisation
    var files = [];
    var titles = [];
    var $headerSlides = params.jqEl;
    var timer = params.timer || 3000;
    var index = 0;

    var $slide      = $('<div class="header-slide"><div class="wrapper"><h2></h2></div></div>');
    var $slide_mask = $('<div class="header-slide-mask"><div class="wrapper"><h2></h2></div></div>');
    var $dots       = $('<div class="header-dots"></div>');
    var $dot        = $('<a href="#"></a>');

    var $figure;
    $headerSlides.find('figure').each(function() {
      $dots.append($dot.clone(true))
      $figure = $(this);
      titles.push($figure.find('h2').html());
      files.push($figure.find('img.header-slides-image').attr('src'));
    });

    $headerSlides.append($slide, $slide_mask);

    if ($headerSlides.find('figure').length === 1) {
      swapSlides();
      return;
    }

    $headerSlides.append($dots);

    // Dots clic
    $dots.on('click', 'a', function(evt) {
      evt.preventDefault();

      index = $(this).index();

      clearInterval(intervalTimer);
      gotoSlide(index);
      intervalTimer = setInterval(nextSlide, timer);
    });

    // Images cycle
    function getNext() {
      if (index + 1 >= files.length)
        return 0
      return index + 1
    }

    function swapSlides() {
      $slide_mask
        .show()
        .css('background-image', 'url("'+ files[index] +'")')
        .find('h2')
        .html(titles[index]);

      index = getNext();

      $slide
        .css('background-image', 'url("'+ files[index] +'")')
        .find('h2')
        .html(titles[index]);
    }

    function changeDots() {
      $dots.find('a').removeClass('active')
                     .eq(index).addClass('active')
    }

    function nextSlide() {
      changeDots();

      $slide_mask.fadeOut('slow', function() {
        swapSlides();
      });
    }

    function gotoSlide(nb) {
      $slide
        .css('background-image', 'url("'+ files[nb] +'")')
        .find('h2')
        .html(titles[nb]);

      nextSlide();
    }

    changeDots();
    swapSlides();
    var intervalTimer = setInterval(nextSlide, timer);
  },

  initPartnersSlider: function(params) {
    var $slider = params.jqEl;

    $slider.sss();
  },

  initRoomDiaporama: function(params) {
    var $slider = params.jqEl;

    $slider.bxSlider({
      slideWidth: 230,
      minSlides: 1,
      maxSlides: 3,
      slideMargin: 0,
      pager: false
    });
  },

  initHotelsSlider: function(params) {
    var $slider = params.jqEl;

    $slider.bxSlider({
      // mode: 'fade', // buggy :/
      auto: true,
      responsive: false,
      slideWidth: 125,
      pager: false,
      controls: false
    });    
  },

  gmapAccess: function(mapOptions, webRoot) {
    var locations = [
      // Parkings
      { icon: 'parking.png', title: '<b>Parking</b> Saint-Martin', latitude: 48.865207, longitude: 2.353690 },
      { icon: 'parking.png', title: '<b>Parking</b> Beaubourg', latitude: 48.861836, longitude: 2.353647 },
      { icon: 'parking.png', title: '<b>Parking</b> Sébastopol', latitude: 48.861518, longitude: 2.349665 },

      // Bus
      { icon: 'bus.png', title: '<b>Grenier Saint-Lazare - Quartier de l\'horloge</b><br>(Bus 29)', latitude: 48.862757, longitude: 2.353713 },
      { icon: 'bus.png', title: '<b>Grenier Saint-Lazare - Quartier de l\'horloge</b><br>(Bus 38,47,75)', latitude: 48.862938, longitude: 2.354516 },
      { icon: 'bus.png', title: '<b>Sébastopol - Etienne Marcel</b><br>(Bus 29)', latitude: 48.863314, longitude: 2.351304 },

      // Metros
      { icon: 'metro.png', title: '<b>Metro 11</b><br>(Rambuteau)', latitude: 48.861928, longitude: 2.353871 },
      { icon: 'metro.png', title: '<b>Metro 4</b><br>(Etienne-Marcel)', latitude: 48.863820, longitude: 2.349002 },
      { icon: 'metro.png', title: '<b>Metro 3, 11</b><br>(Arts et Métiers)', latitude: 48.865464, longitude: 2.356115 },
      { icon: 'metro.png', title: '<b>Metro 1, 4, 7, 11, 14</b><br>(Châtelet)', latitude: 48.858716, longitude: 2.347433 },
      { icon: 'metro.png', title: '<b>Metro 3, 4</b><br>(Réaumur-Sébastopol)', latitude: 48.866247, longitude: 2.352548 },

      // RER
      { icon: 'rer.png', title: '<b>RER A,B,D</b><br>(Châtelet - Les Halles)', latitude: 48.861915, longitude: 2.346988 },

      // Autolib
      { icon: 'autolib.png', title: '<b>Autolib</b>', latitude: 48.863424, longitude: 2.352609 },

      // Vélib
      { icon: 'velib.png', title: '<b>Velib</b>', latitude: 48.863163, longitude: 2.352652 },

      // ESM
      { title: '<b>Espace Saint-Martin</b><br/>199bis, Rue Saint-Martin<br/>75003 Paris', latitude: 48.863377, longitude: 2.352451}
    ];

    var map = new google.maps.Map(document.getElementById("gmap_canvas"), mapOptions);
    var infowindow = new google.maps.InfoWindow();
    
    var marker, markerParams, location;
    for (var i = 0; i < locations.length; i++) {
      location = locations[i];

      markerParams = {
        position: new google.maps.LatLng(location.latitude, location.longitude),
        map: map
      };

      if (location.icon)
        markerParams.icon = webRoot + '/images/site_layout/mapicons/' + location.icon;

      marker = new google.maps.Marker(markerParams);

      google.maps.event.addListener(marker, 'click', (function(marker, location) {
        return function() {
          infowindow.setContent(location.title);
          infowindow.open(map, marker);
        }
      })(marker, location));
    }
    
    infowindow.setContent(locations[locations.length-1].title);
    infowindow.open(map, marker);
  }
}