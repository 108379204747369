'use strict';

module.exports = {
  init: function() {

    var $labels = $('label');

    $labels.on('click', '[type=checkbox]', function() {
      var $checkbox   = $(this);
      var $label      = $checkbox.parent();
      var $div        = $label.find('div');
      var $underfield = $div.find('input');

      $underfield.prop('disabled', !$underfield.prop('disabled'));

      if ($label.hasClass('field-enabled')) {
        $label
          .removeClass('field-enabled')
          .addClass('field-disabled');
       
        $underfield.val(null);
      }
      else {
        $label
          .removeClass('field-disabled')
          .addClass('field-enabled');
        
        if ($underfield.get(0))
          $underfield.get(0).focus();
        if ($underfield.prop('type') === 'number')
          $underfield.val(1)
      }
    });
    
  }
}